<template>
    <div>
        <page-title :heading="this.$t('analytic.lang_salesbyType')" :icon="icon"
                    :subheading="this.$t('analytic.lang_salesbyType')"></page-title>
        <div class="app-main__inner">
            <categories-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import CategoriesComponent from "../../../../components/analytics/warewanlytics/Categories/CategoriesComponent";
    export default {
        name: "index",
        components: {CategoriesComponent, PageTitle},
        data: () => ({
            icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
        })
    }
</script>

<style scoped>

</style>